import React from 'react';
import Section from '../components/Section';
import Metadata from '../components/Metadata';

function einzelcoaching() {
  return (
    <>
      <Metadata title='Einzelcoaching' />
      <Section heading='Systemisches Einzelcoaching'>
        <p>
          Dabei handelt es sich um ein Beratungsformat, welches bei der
          Erreichung komplexer Ziele in bestimmten Kontexten unterstützt - z.B.
          im Hinblick auf schulische Erfolge oder einen guten Einstieg an einem
          bestimmten Arbeitsplatz. Hier geht es um eine gute Passung im
          Person-Umwelt-Gefüge und somit um die Vermittlung zwischen
          individuellen Merkmalen und Erfordernissen in einer Organisation oder
          etwa auch in einer spezifischen Rolle. Spannungssituation in diesem
          Gefüge werden genau analysiert und kreativ individuelle Lösungen
          gesucht, oft unter Einbezug von anderen Menschen aus dem jeweiligen
          Lebensbereich. Das Ziel ist dabei immer eine Verbesserung der
          Selbstorganisationsfähigkeit des/der Klient:inen.
        </p>
      </Section>
    </>
  );
}

export default einzelcoaching;
